import { useState } from "react";
import VAForm from "./VAForm";
import VAFormSpanish from "./VAFormSpanish";

function App() {
  const [currentComponent, setCurrentComponent] = useState("English");
  const [isChecked, setIsChecked] = useState(true);

  const loadComponentOne = () => {
    setIsChecked(true);
    setCurrentComponent("English");
  };

  const loadComponentTwo = () => {
    setIsChecked(false);
    setCurrentComponent("Spanish");
  };

  return (
    <>
      <div className="d-grid gap-2 col-2 mx-auto m-2">
        <div
          className="btn-group-horizontal"
          role="group"
          aria-label="Vertical radio toggle button group"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            fill="currentColor"
            className="bi bi-translate"
            viewBox="0 0 16 16"
          >
            <path d="M4.545 6.714 4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z" />
            <path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846-.748.575-1.673 1.001-2.768 1.292.178.217.451.635.555.867 1.125-.359 2.08-.844 2.886-1.494.777.665 1.739 1.165 2.93 1.472.133-.254.414-.673.629-.89-1.125-.253-2.057-.694-2.82-1.284.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492 1.988 1.988 0 0 1-.94.31z" />
          </svg>{" "}
          <span> </span>
          <input
            type="radio"
            className="btn-check"
            name="vbtn-radio"
            id="vbtn-radio2"
            autoComplete="off"
            onClick={loadComponentOne}
            checked={isChecked}
          />
          <label className="btn btn-outline-primary" htmlFor="vbtn-radio2">
            English
          </label>
          <input
            type="radio"
            className="btn-check"
            name="vbtn-radio"
            id="vbtn-radio3"
            autoComplete="off"
            onClick={loadComponentTwo}
          />
          <label className="btn btn-outline-primary" htmlFor="vbtn-radio3">
            español
          </label>
        </div>
      </div>

      {currentComponent === "English" && <VAForm />}
      {currentComponent === "Spanish" && <VAFormSpanish />}
      <footer className="p-3">
        <center>
          <a
            href={"https://careconnectmd.com"}
            rel="noopener noreferrer"
            target="_blank"
          >
            CareConnectMD
          </a>
          <span> ©2024</span>
        </center>
      </footer>
    </>
  );
}

export default App;
