import React, { useState } from "react";
import SignaturePad from "./SignaturePad";
import { ValidateForm } from "./ValidateForm";
import axios from "axios";
import BackdropSpinner from "./BackdropSpinner";
import { apiUrl } from "./GlobalConfig";

const VAForm = () => {
  const [loading, setLoading] = useState(false);

  const [medicarenum, setMedicarenum] = useState("");
  const [signatureCleared, setSignatureCleared] = useState(false);

  const [errors, setErrors] = useState({});

  // Format the date as "YYYY-MM-DD" for the input field
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so add 1
  const day = currentDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;

  const handleSignatureEnd = (dataURL) => {
    //setSignature(dataURL);
    setFormData({ ...formData, signature: dataURL.split(",")[1] });
  };

  const [formData, setFormData] = useState({
    language: "EN",
    providerName: "",
    beneficiaryName: "",
    medicareNumber: "",
    printName: "",
    signedDate: formattedDate,
    signature: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, language: "EN", [name]: value });
  };

  const medicarenumberChange = (e) => {
    const value = e.target.value;
    if (value.includes(" ")) {
      const sanitizedValue = value.replace(/\s/g, "");
      setMedicarenum(sanitizedValue);
    } else {
      const sanitizedIn = value.replace(/[^a-zA-Z0-9]/g, "");
      setMedicarenum(sanitizedIn);
    }
    //const medistring = "PR" + e.target.value.toString();
    //const mbase64 = btoa(medistring);
    setFormData({
      ...formData,
      medicareNumber: e.target.value,
    });
  };

  const saveForm = async (request) => {
    setLoading(true);
    try {
      const response = await axios.post(apiUrl, request);

      // Handle the response from the API as needed
      console.log("Response from API:", response.data);
      setSignatureCleared(false);
      if (response.data === "OK") {
        alert(response.data);
      } else {
        alert(response.data);
      }
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("Error:", error);
      alert(" From Submission Failed!");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Include logic to submit the form data along with the signature.
    console.log("Form Data:", { formData });
    const validationErrors = ValidateForm(formData);

    console.log(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setSignatureCleared(true);
      // Form is valid, submit the data
      saveForm(formData);
      setFormData({
        providerName: "",
        beneficiaryName: "",
        medicareNumber: "",
        printName: "",
        signedDate: formattedDate,
        signature: "",
      });
      setMedicarenum("");
    } else {
      // Form is invalid, set validation errors
      setErrors(validationErrors);
    }
  };
  return (
    <>
      {loading && <BackdropSpinner show={loading} />}

      <div
        className="vaFont Bmt-3 col-md-7 mx-auto"
        style={{ maxWidth: 1500, padding: "2em", border: "solid" }}
      >
        <img src="./CCMDatHome.png" alt="CCMD logo" className="p-3" />
        <img
          src="./CMS_logo.png"
          alt="CMS logo"
          style={{ height: 70, marginLeft: "20em" }}
        />
        <h4 className="vaFont21B">Dear Medicare Beneficiary:</h4>
        <div className="vaFont24B d-flex my-4">
          <img
            src="./edit.png"
            width="80"
            alt=""
            style={{ display: "float" }}
          />
          <h3 className="vaFont24B">
            Please complete this form to confirm our practice is the main place
            you go for routine care.
          </h3>
        </div>

        <p className="vaFont17 lh-sm">
          Medicare has started an initiative where health care providers who
          share a common set of goals aimed at improving patient care can work
          together more effectively. This initiative brings together health care
          professionals in an Accountable Care Organization (ACO), to work
          together with Medicare to give you more coordinated care and services.
        </p>

        <p className="vaFont17 lh-sm">
          Your doctor or health care professional is voluntarily taking part in
          this new initiative by joining <strong>CareConnectMD at Home </strong>
          because we think it will help us provide better quality care for our
          patients.
        </p>

        <p className="vaFont17 lh-sm">
          You are receiving this letter and form because your doctor or other
          health care professional thinks that you might benefit from care
          coordination and preventive services offered by CareConnectMD at Home.
        </p>

        <p className="vaFont17 lh-sm">
          CareConnectMD At Home will work together with your doctor and other
          healthcare professionals so you can get the right care at the right
          time. CareConnectMD At Home will coordinate your care according to
          your individual medical needs and treatment choices. CareConnectMD At
          Home will protect your medical records and privacy. CareConnectMD At
          Home will work to reduce duplicate tests and duplicate paperwork that
          costs you time and money.
        </p>
        <p className="vaFont17 lh-sm">
          You can use this form to confirm that our practice is the main doctor
          or other health care professional you see or the main place you go for
          routine care, to help determine if CareConnectMD at Home should help
          coordinate your care. Routine care can include regular care and
          check-ups you get from a doctor or other health care professional and
          care for other chronic health problems, such as asthma, diabetes, and
          hypertension.
        </p>
        <h5 className="vaFont19B mb-3" style={{ textAlign: "center" }}>
          Please return your completed form as soon as possible
        </h5>

        <p className="vaFont17 lh-sm">
          Alternatively, instead of returning this form, you can also log into
          Medicare.gov and select your main doctor or other health care
          professional to determine whether CareConnectMD at Home should help
          with coordinating your care. If you make a selection on this form and
          make a different selection through Medicare.gov, Medicare will
          prioritize the most recently submitted selection.
        </p>

        <h5 className="vaFont21B mb-3">
          Your benefits will NOT change, and you can visit any doctor, other
          health care professional, or hospital.
        </h5>

        <p className="vaFont17 lh-sm">
          Whether or not you complete this form or select a doctor or other
          health care professional through Medicare.gov, you remain eligible to
          receive the same Medicare benefits and you still have the right to use
          any doctor, other health care professional, or hospital that accepts
          Medicare, at any time. If you have questions, feel free to ask your
          doctor or other health care professional, call CareConnectMD at Home
          at 888-874-0818, or call Medicare at 1-800-MEDICARE (1-800-633-4227)
          to ask about ACOs. TTY users should call 1-877-486-2048.
        </p>

        <p className="vaFont17 lh-sm">
          <strong>
            Completing this form or selecting a doctor or other health care
            professional through Medicare.gov is your choice AND you can change
            your mind.
          </strong>
        </p>
        <p className="vaFont17 lh-sm">
          If you choose to complete this form or select a doctor or other health
          care professional through Medicare.gov you should do so yourself. No
          one else should complete this for you.
        </p>

        <p className="vaFont17 lh-sm">
          No one is allowed to attempt to influence your choice to complete this
          form or select a doctor or other health care professional through
          Medicare.gov by offering or withholding anything in exchange for you
          to complete or not complete the form or to make a selection online. If
          you feel pressured to sign or not sign this form or to make a
          selection online, please call 1-800-MEDICARE (1-800-633-4227). TTY
          users should call 1-877-486-2048.
        </p>

        <p className="vaFont17 lh-sm">
          Please call CareConnectMD AT Home at 888-874-0818 or update your
          online selection if you change your mind later about whether you
          consider our practice to be the main doctor or other health care
          professional you see or the main place you go for routine care.
        </p>

        <p className="vaFont17 lh-sm">
          Sincerely,
          <br /> CareConnectMD At Home
        </p>

        <div className="mt-5 border border-dark p-2">
          <h3 className="vaFont24B">Get more information about ACOs.</h3>

          <div className="lh-1" style={{ fontWeight: 600 }}>
            CMS Website: <span> </span>
            <a
              href="https://innovation.cms.gov/innovation-models/aco-reach"
              className="lh-1"
            >
              https://innovation.cms.gov/innovation-models/aco-reach
            </a>
          </div>

          <div className="lh-1 mt-3" style={{ fontWeight: 600 }}>
            ACO Website: <span> </span>
            <a href="https://www.careconnectmd.com/aco-reach" className="lh-1">
              https://www.careconnectmd.com/aco-reach
            </a>
          </div>
        </div>
        <div>
          <img src="./CCMDatHome.png" alt="CCMD logo" className="mt-5" />

          <img
            src="./CMS_logo.png"
            alt="CMS logo"
            style={{ height: 80, marginLeft: "20rem" }}
            className="mt-5"
          />
        </div>
        <h4 className="vaFont22B mt-3">
          Confirmation of Main Doctor or Other Healthcare Professional Form
        </h4>

        <div
          className="border border-dark"
          style={{ margin: "12px -10px", padding: "8px 24px" }}
        >
          <h3 className="vaFont24B">1.CONFIRM</h3>
          <p className="vaFont17">
            By signing below, I am confirming that my main doctor or other
            healthcare professional – or the main place I go to for routine
            medical care – is:
          </p>
          <div className="container">
            <div className="row">
              <div className="col-md-6 my-1" style={{ alignItems: "center" }}>
                <h6 style={{ fontSize: "17px", flexBasis: "50%" }}>
                  Provider Name and/or Medical Group
                </h6>
              </div>
              <div className="col-md-6 my-1">
                <input
                  type="text"
                  className="ms-3 p-2 w-100"
                  maxLength={30}
                  autoComplete="on"
                  id="ProviderName"
                  name="providerName"
                  value={formData.providerName}
                  onChange={handleChange}
                />
                {errors.providerName && (
                  <span className="ms-3" style={{ color: "red" }}>
                    {errors.providerName}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 my-1" style={{ alignItems: "center" }}>
                <h6 style={{ fontSize: "17px", flexBasis: "50%" }}>
                  Beneficiary Name
                </h6>
              </div>
              <div className="col-md-6 my-1">
                <input
                  type="text"
                  className="ms-3 p-2 w-100"
                  autoComplete="on"
                  maxLength={30}
                  id="BeneficiaryName"
                  name="beneficiaryName"
                  value={formData.beneficiaryName}
                  onChange={handleChange}
                />
                {errors.beneficiaryName && (
                  <span className="ms-3" style={{ color: "red" }}>
                    {errors.beneficiaryName}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="d-flex mt-3">
            <div style={{ flexBasis: "65%" }}>
              <SignaturePad
                onEnd={handleSignatureEnd}
                onClear={signatureCleared}
              />
            </div>
            <div className="ps-2 align-self-end" style={{ flexBasis: "35%" }}>
              <input
                type="date"
                readOnly
                className="w-100 p-2"
                id="SignedDate"
                name="signedDate"
                value={formData.signedDate}
                onChange={handleChange}
              />
              <h6>
                Date
                <span style={{ fontSize: "17px", color: "red" }}>
                  (Required)
                </span>
              </h6>
            </div>
          </div>
          <div className="col-md-7">
            <h6 className="vaFont17">Signature </h6>
            <input
              type="text"
              className="w-100 p-2"
              autoComplete="on"
              maxLength={35}
              id="PrintName"
              name="printName"
              value={formData.printName}
              onChange={handleChange}
            />

            <h6 className="vaFont17">
              Printed Name of Signatory{" "}
              {errors.printName && (
                <span className="ms-3" style={{ color: "red" }}>
                  {errors.printName}
                </span>
              )}
            </h6>
            <input
              type="text"
              className="w-100 p-2"
              maxLength={11}
              id="medicareNumber"
              name="medicareNumber"
              value={medicarenum}
              onChange={medicarenumberChange}
            />

            <h6 className="vaFont17">
              Medicare Number{" "}
              {errors.medicareNumber && (
                <span className="ms-3" style={{ color: "red" }}>
                  {errors.medicareNumber}
                </span>
              )}
            </h6>
          </div>

          <p className="vaFont17 lh-sm mt-3">
            Note: If the names listed above and in the attached letter are
            incorrect do not sign this form. If you would like to receive a new
            form with a different doctor, other healthcare professional or
            practice listed, please call CareConnectMD at Home at 888-874-0818.
          </p>

          <button className="btn btn-success mx-1" onClick={handleSubmit}>
            Submit
          </button>
        </div>

        <h3 className="vaFont24B">2.RETURN</h3>
        <p className="vaFont17B lh-sm" style={{ fontWeight: 600 }}>
          <img src="./mail.png" width="4%" style={{ display: "float" }} />
          <span> </span>Return this form to our practice via secure fax or mail
          to:
        </p>
        <div className="d-flex">
          <p style={{ fontSize: "17px", flexBasis: "50%" }}>
            CareConnectMD At Home <br />
            3090 Bristol St Ste 200
            <br /> Costa Mesa, CA 92626
          </p>
          <div className="vaFont17">
            FAX: <br />
            877-738-0868
          </div>
        </div>
        <p className="vaFont17 lh-sm mt-3" style={{ fontWeight: 600 }}>
          Note: Completing and returning this form is voluntary. It won’t affect
          your Medicare benefits. This form is not valid unless it includes both
          a signature and date.
        </p>
        <p className="vaFont17">Rev Jan 2024</p>
      </div>
    </>
  );
};
export default VAForm;
