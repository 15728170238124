export const ValidateForm = (data) => {
  const errors = {};

  if (!data.providerName) {
    errors.providerName = "Provider Name is required";
  }

  if (!data.beneficiaryName) {
    errors.beneficiaryName = "Beneficiary Name is required";
  }
  if (!data.printName) {
    errors.printName = "Printed Name is Required";
  }

  // if (!data.medicareNumber) {
  //   errors.medicareNumber = "Medicare Number is required";
  // } else
  if (data.medicareNumber.length > 0 && data.medicareNumber.length < 11) {
    errors.medicareNumber = "Medicare Number must be 11 characters";
  }

  return errors;
};
