// SignaturePad.js
import React, { useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

const SignaturePad = ({ onEnd, onClear }) => {
  const sigCanvas = useRef({});

  useEffect(()=>{
      handleClear();
  },[onClear])

  const handleClear = () => {
    sigCanvas.current.clear();
    };

  return (
    <div>
      <SignatureCanvas
        penColor="black"
        backgroundColor="#faf7f7"
        canvasProps={{
          width: 400,
          height: 80,
          className: "sigCanvas",
        }}
        ref={(ref) => {
          sigCanvas.current = ref;
        }}
        onEnd={() => onEnd(sigCanvas.current.toDataURL())}
      />
      <button className="btn btn-secondary mx-1" onClick={handleClear}>
        Clear
      </button>
    </div>
  );
};

export default SignaturePad;
