import React from "react";
import "./custom.css";

function BackdropSpinner({ show }) {
  return (
    <div className={`backdrop-spinner ${show ? "visible" : "hidden"}`}>
      <div
        className="spinner-border text-info"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export default BackdropSpinner;
